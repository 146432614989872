.panel-group {

}

.panel {
  border: none;
  box-shadow: none;

  .panel-heading {
    background-color: #dee4f2;
    color: #4E5A61;
    border-radius: 0;
  }

  .panel-collapse {

  }
}
