// Colors
$green: #A9B811;
$red: #E95056;

// Type
$font-weight-bold: 700;
$font-size-base: 14px;
$font-size-small: 12px;

// Buttons
$btn-height: 50px;
$btn-border-radius: $btn-height * 2;
$btn-font-size: $font-size-base;
$btn-font-weight: $font-weight-bold;

$btn-sm-height: 30px;
$btn-sm-border-radius: $btn-sm-height * 2;
$btn-sm-font-size: $font-size-small;
