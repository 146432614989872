form {
  .form-control, .form-control::placeholder {
    color: #4E5A61;
  }

  &.form-auth {
    a {
      .green {
        font-weight: 900;
        color: #A9B811;
      }
    }
  }

  .help-block {
    color: red;
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .form-group {
    label {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 16px;
      color: #617179;
      margin-bottom: 10px;

      span.required {
        color: #AE1A20;
      }
    }

    input.form-control {
      background: none;
      background-color: #FFF;
      border: 2px solid #D3D9DB;
      height: 50px;
      border-radius: 2px;
      box-shadow: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;

      &::placeholder{
        color: #677889;
      }
    }

    textarea {
      background: none;
      background-color: #FFF;
      border: 2px solid #D3D9DB;
      height: 133px;
      border-radius: 2px;
      box-shadow: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;
      resize: none;

      &::placeholder{
        color: #677889;
      }
    }

    select {
      background: none;
      background-color: #FFF;
      border: 2px solid #D3D9DB;
      height: 50px;
      border-radius: 2px;
      box-shadow: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4E5A61;

      &::placeholder{
        color: #677889;
      }
    }
  }

  input[type="submit"], button[type="submit"] {
    height: 50px;
    line-height: 50px;
    background: none;
    border: none;
    background-color: #DCE84C;
    box-shadow: 0 1px 0 0 #A9B811;
    border-radius: 100px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #143C96;
    -webkit-font-smoothing: antialiased;
    width: 100%;
  }

  .btn-disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  .radio {
    margin-top: 15px;
    margin-bottom: 15px;

    input[type="radio"] {
      margin-top: 7px; // to align it with text
    }

    label {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #617179;
      line-height: 27px;
      -webkit-font-smoothing: antialiased;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      span.bold {
        font-weight: 700;
      }

      div.exp-date {
        display: inline-block;
        vertical-align: middle;
        float: right;
      }

      span.icon {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 27px;
        width: 41px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }

      span.paypal {
        border: none;
        border-radius: 0px;
        background-color: transparent;
        height: 18px;
        width: 68px;
        margin-right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url('../img/paypal-bg.png');
      }

      span.icon.visa {
        background-image: url('../img/credit-card.png');
      }

      span.icon.amex {
        background-image: url('../img/credit-card-amex.png');
      }

      span.price {
        float: right;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #143C96;
      }

      a {
        float: right;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 700;
        color: #143C96;
      }
    }
  }

  // This is to avoid a zoom problem in iOs devices
  // https://stackoverflow.com/a/6394497/923323
  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
  }
}

// Monkey patch
.product-price-selector__option {
  .radio {
    input[type="radio"] {
      margin-top: 10px !important;
    }
  }
}