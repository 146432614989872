.store-search-form {
  margin-bottom: 30px;
  margin-top: 0;

  p {
    margin-bottom: 10px;
  }

  strong {
    font-weight: bold;
  }
}

.store-search-form__input {
  background-color: white;
  border: 1px solid #EEEEEE;
  border-radius: 23px;
  position: relative;

  input {
    border: none !important;
    box-shadow: none !important;
    background: none;
    width: 100% !important;
    height: 45px;
    line-height: 45px;
    padding-left: 23px;
    padding-right: 50px;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent !important;
    padding: 0 !important;
    box-shadow: none !important;
    min-width: auto !important;
    line-height: inherit !important;
    width: 45px !important;
    height: 45px !important;
  }
}