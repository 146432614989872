@mixin button-size($font-size, $height, $radius){
  font-size: $font-size;
  line-height: $height;
  height: $height;
  border-radius: $radius;
  padding: 0 $height / 2;
}

@mixin button-variant($background, $color, $shadow-color){
  color: $color;
  background: $background;
  box-shadow: 0 1px 0 0 $shadow-color;

  &:focus,
  &:hover {
    color: $color;
    background: $background;
    box-shadow: 0 1px 0 0 $shadow-color;
  }

  &:active {
    background: darken($background, 10%);
    box-shadow: 0 1px 0 0 darken($shadow-color, 10%);
  }
}