.location-card {
  padding-bottom: 15px;

  label {
    display: flex;

    > div {
      &:first-child {
        margin-right: 10px;
      }
    }

    p {
      font-weight: normal;
      margin-bottom: 5px;
    }
  }
  .ship-limit-message{
    font-weight: bold;
    text-decoration: underline;
  }
}