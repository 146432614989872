.custom-checkbox {
	position: relative;
	display: inline-block;
	margin-bottom: 20px;
  label {
    cursor: pointer;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 2px;
    left: 0;
    background: #FFF;
    border:1px solid #EBEBEB;
  }
  label:after {
  	opacity: 0.0;
  	content: '';
  	position: absolute;
  	width: 9px;
  	height: 5px;
  	background: transparent;
  	top: 3px;
  	left: 2px;
  	border: 3px solid #143C96;
  	border-top: none;
  	border-right: none;
  	transform: rotate(-45deg);
  }
  label:hover::after {
  	opacity: 0.0;
  }
	span.text{
    display: inline-block;
    margin-left: 5px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #617179;
	}
  input[type=checkbox]{
    margin: 0px;
  }
  input[type=checkbox]:checked + label:after {
  	opacity: 1;
  }
}