.validation-success {
  .email-address {
    display: inline-block;
    background-color: #F8F6F6;
    color: #143C96;
    padding: 20px 40px;
    margin-bottom: 30px;

    img {
      height: 14px;
      margin-right: 10px;
    }

    span {
      font-size: 17px;
    }
  }

  .subtitle {
    margin-bottom: 50px;
  }

  .orders {
    border: 1px solid #EBEBEB;
    padding: 30px;
    margin-bottom: 50px;
    background-color: white;
  }
}