.checkout-radio {

}

.checkout-radio__label {

}

.checkout-radio__actions {
  padding-left: 20px;  // same as the label

  button {
    padding: 10px 30px 20px 0;
    color: #143C96;
    background: none;
    border: none;
    box-shadow: none;
    font-weight: $font-weight-bold;
  }
}
