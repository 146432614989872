section.page-content {
  padding-bottom: 30px;

  .wrapper {
    padding: 15px;
    border: 1px solid #EBEBEB;
    border-radius: 2px;

    h2 {
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 20px;
      color: #4E5A61;
      line-height: 34px;
      margin: 0;
      margin-bottom: 30px;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      span.icon {
        font-size: 20px;
        color: #143C96;
        margin-right: 10px;
      }

      span.text {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 16px;
        color: #617179;
        -webkit-font-smoothing: antialiased;
      }
    }

    .section-title {
      position: relative;
    }

    .filters {
      position: absolute;
      right: 0;
      top: 0;

      form {

        .form-group {
          margin-bottom: 0;

          select.form-control {
            background-color: #FFF;
            border: 1px solid #D3D9DB;
            border-radius: 2px;
            padding: 0 15px;
            padding-right: 40px;
            font-family: 'Arimo', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #4E5A61;
            line-height: 16px;
            margin-bottom: 0;
            height: 36px;
            -webkit-appearance: none;
            background: url('../img/select-bg.png') no-repeat;
            background-position: right 15px center;
            background-size: 8px;
            -webkit-font-smoothing: antialiased;

            &::placeholder{
              color: #677889;
            }
          }
        }
      }

      a.btn-custom {
        display: inline-block;
        height: 35px;
        padding: 10px 20px;
        line-height: 16px;
        background: #DCE84C;
        box-shadow: 0 1px 0 0 #A9B811;
        border-radius: 100px;
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #143C96;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        span.icon {
          color: #143C96;
          font-size: 8px;
          font-weight: 700;
          margin-right: 3px;
        }
      }
    }

    &.orders {
      padding-bottom: 30px;

      .order-item {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #EBEBEB;

        .options {

          a {
            text-decoration: none;

            span.btn-custom {
              background: #FFFFFF;
              border: 2px solid #143C96;
              border-radius: 100px;
              font-family: 'Roboto', sans-serif;
              font-weight: 700;
              font-size: 14px;
              color: #143C96;
              display: inline-block;
              line-height: 32px;
              height: 35px;
              width: 100%;
              text-align: center;
              margin-bottom: 15px;
            }

            span.btn-custom.cancel {
              border-color: #B9B9B6;
              background: #B9B9B6;
              box-shadow: 0 1px 0 0 #9A9A93;
              color: #FFFFFF;
            }

            span.tracking {
              border-color: #DCE84C;
              background: #DCE84C;
              box-shadow: 0 1px 0 0 #A9B811;
              color: #143C96;
            }
          }
        }
      }

      .order-item:last-child {
        border-bottom: none;
        margin-bottom: none;
      }

      h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 13px;
        color: #617179;
        margin: 0;
        margin-bottom: 10px;
      }

      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #617179;
        line-height: 19px;
        margin-bottom: 10px;

        span {
          font-weight: 700;
        }
      }

      .status {
        margin-bottom: 20px;

        span {
          display: inline-block;
          vertical-align: middle;
          line-height: 24px;
        }

        span.circle {
          height: 14px;
          width: 14px;
          border-radius: 14px;
          background-color: #EEE;
          margin-right: 5px;
        }

        span.text {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .status.delivered {

        span.circle {
          background-color: #78C339;
        }

        span.text {
          color: #78C339;
        }
      }

      .status.in-transit {

        span.circle {
          background-color: #F4C400;
        }

        span.text {
          color: #F4C400;
        }
      }

      .status.awaiting {

        span.circle {
          background-color: #FF7E00;
        }

        span.text {
          color: #FF7E00;
        }
      }

      .status.cancelled {

        span.circle {
          background-color: #E95056;
        }

        span.text {
          color: #E95056;
        }
      }

      .items-section {
        margin-bottom: 20px;
      }

      .payment-info {

        h6 {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 14px;
          color: #617179;
          margin: 0;
          margin-bottom: 10px;
        }

        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #617179;
          line-height: 27px;

          span {
            display: inline-block;
            vertical-align: middle;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #617179;
            line-height: 27px;
          }

          span.icon {
            border: none;
            border-radius: 0;
            background-color: transparent;
            height: 27px;
            width: 41px;
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }

          span.paypal {
            border: none;
            border-radius: 0;
            background-color: transparent;
            height: 18px;
            width: 68px;
            margin-right: 10px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('../img/paypal-bg.png');
          }

          span.icon.visa {
            background-image: url('../img/credit-card.png');
          }

          span.icon.amex {
            background-image: url('../img/credit-card-amex.png');
          }

          span.icon.mastercard {
            background-image: url('../img/credit-card-mastercard.png');
          }

          span.icon.discover {
            background-image: url('../img/credit-card-discover.png');
          }
        }
      }

      .shipping-info {

        h6 {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #617179;
          margin: 0;
          margin-bottom: 10px;
        }

        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #617179;
          line-height: 27px;
        }
      }

      .summary {

        h6 {
          font-family: 'Roboto', sans-serif;
          font-weight: 700;
          font-size: 13px;
          color: #617179;
          margin: 0;
          margin-bottom: 10px;
        }

        p {
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #617179;
          line-height: 27px;
        }

        table {

          tbody {

            tr {

              td {
                font-family: 'Roboto', sans-serif;
                font-weight: 400;
                font-size: 14px;
                color: #617179;
                line-height: 27px;
                border: none;
                padding: 0;
                -webkit-font-smoothing: antialiased;
              }

              td:last-child {
                text-align: right;
              }
            }

            tr.total {

              td {
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                font-size: 14px;
                color: #617179;
                line-height: 27px;
                -webkit-font-smoothing: antialiased;
              }
            }
          }
        }
      }

      .box {
        padding: 10px;
        background-color: #F6FAFB;
        border-radius: 2px;
        margin-bottom: 20px;
        border: 1px solid #EBEBEB;

        p:last-child {
          margin-bottom: 0;
        }
      }

      .more-details {

        p {
          text-align: right;

          a {
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #143C96;
            line-height: 19px;
          }

          span {
            display: inline-block;
            vertical-align: middle;
            font-weight: 400;
          }

          span.icon {
            font-weight: 300;
            font-size: 7px;
            color: #999999;
            margin-left: 10px;
          }
        }

        .more-items {
          padding: 10px;
          background-color: #F6FAFB;
          border-radius: 2px;
          margin-bottom: 20px;
          border: none;

          p {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-size: 14px;
            color: #617179;
            line-height: 19px;
            text-align: center;
            margin: 0;

            strong {
              font-weight: 400;
              color: #153D97;
            }
          }
        }
      }
    }

    &.return {

      .options {

        .btn-custom {
          line-height: 32px;
          height: 35px;
        }

        a {
          text-decoration: none;

          span.btn-custom {
            background: #FFFFFF;
            border: 2px solid #143C96;
            border-radius: 100px;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: 14px;
            color: #143C96;
            display: inline-block;
            line-height: 32px;
            height: 35px;
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
          }

          span.btn-custom.cancel {
            border-color: #B9B9B6;
            background: #B9B9B6;
            box-shadow: 0 1px 0 0 #9A9A93;
            color: #FFFFFF;
          }

          span.tracking {
            border-color: #DCE84C;
            background: #DCE84C;
            box-shadow: 0 1px 0 0 #A9B811;
            color: #143C96;
          }
        }
      }

      h6 {
        font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #617179;
        margin: 0;
        margin-bottom: 10px;
      }

      p {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #617179;
        line-height: 19px;
        margin-bottom: 20px;

        span.value {
          display: block;
          font-weight: 700;
          font-size: 16px;
        }
      }

      .item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #EBEBEB;

        .info {
          display: inline-block;

          .photo {
            border-radius: 2px;
            display: inline-block;
            vertical-align: middle;
            width: 62px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-left: 5px;

            img {
              width: 100%;
            }
          }

          .content {
            display: inline-block;
            vertical-align: middle;

            p {
              font-family: 'Roboto', sans-serif;
              font-weight: 400;
              font-size: 14px;
              color: #617179;
              margin-bottom: 5px;

              span {
                font-weight: 400;
              }

              span.qty {
                margin-left: 20px;
              }
            }

            p:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      form {

        .form-group {
          margin-top: 10px;

          label {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-weight: 14px;
            color: #617179;
            line-height: 19px;
          }

          input, select, textarea {
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            font-weight: 14px;
            color: #617179;
            border: 1px solid #EBEBEB;
          }
        }
      }
    }

    &.addresses {
      padding-bottom: 30px;

      .address-item {
        padding: 15px;
        margin-bottom: 15px;
        border: 1px solid #E8E8E8;
        border-radius: 2px;
        position: relative;
        hr {
          margin-bottom: 15px;
        }

        &.address-item--default {
          border: 1px solid #143C96;
        }

        .address-item__content {
          line-height: 20px;
          .row {
            > div {
              margin-bottom: 0;
            }
          }

          .address-item__by-default {
            p[class^="address-item__by-default__"] {
              color: #143C96;
              font-size: 11px;
              font-weight: 600;
              text-transform: uppercase;
              text-align: right;
              margin-bottom: 2px;
            }
          }

          h6 {
            color: #617179;
            font-weight: 900;
          }

          .address-item__content__address {
            margin-bottom: 15px;
            p {
              margin-bottom: 10px;
            }
          }
        }

        .address-item__actions {
          &.address-item__actions--center {
            text-align: center;
          }

          > div[class^="address-item__actions__"] {
            display: inline-block;
            padding: 0 10px 0 0;
            i[class~="fa"] {
              margin-right: 2px;
            }
          }

          .address-item__actions__status {
            .dropdown.btn-group {
              .btn {
                background: none;
                box-shadow: none;
                border-radius: 0;
                padding: 0;
              }
            }
          }

          .address-item__actions__hover {
            cursor: pointer;
          }

          > div[class^="address-item__actions__"]:nth-child(2) {
            &.address-item__actions__delete--bar-right {
              border-right: 2px solid #EBEBEB;
            }
          }

          > div[class^="address-item__actions__"]:last-child {
            padding-left: 10px;
          }

          .address-item__actions__status {
            .address-item__actions__status__default {
              color: #143C96;
              font-weight: 900;
            }

            .address-item__actions__status__set-as-default {
              .dropdown.btn-group {
                margin-left: 5px;
              }
            }
          }
        }
      }

      .address-item:last-child {
        margin-bottom: 0;
      }
    }
  }
}