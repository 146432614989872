body {
  font-family: 'Roboto', sans-serif;
  color: #4E5A61;
  font-size: 14px;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: 700;
  color: #4E5A61;
}

// Here I've put the font-sizes I found in other SCSS files, but this will
// need a refactor because h4 to h6 are miss used.

h1, .h1 {
  font-size: 20px;
}

h2, .h2 {
  font-size: 16px;
}

h3, .h3 {
  font-size: 14px;
}

select {
  -webkit-appearance: none;
}

p {
  -webkit-font-smoothing: antialiased;
}

a, a:hover, a:focus {
  -webkit-font-smoothing: antialiased;
  text-decoration: none;
}

label {
  -webkit-font-smoothing: antialiased;
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5),
screen and (-moz-min-device-pixel-ratio: 1.5),
screen and (min-device-pixel-ratio: 1.5) {

}