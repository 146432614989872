.btn {
  font-family: 'Roboto', sans-serif;
  font-weight: $font-weight-bold;
  border: none;
  text-align: center;
  text-transform: uppercase;
  @include button-variant(#DCE84C, #143C96, #A9B811);
  @include button-size($btn-font-size, $btn-height, $btn-border-radius);

  span.icon {
    margin-right: 6px;
  }
}

.btn-blue {
  @include button-variant(#153D97, white, #072059);
}

.btn-sm {
  @include button-size($btn-sm-font-size, $btn-sm-height, $btn-sm-border-radius);
}

.btn-gray {
  @include button-variant(#626c71, white,  #626c71);
}