span.product-type__icon {
  height: 15px;
  margin-top: 5px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;

  &.icon-default {
    background-image: url("../img/productTypeIcons/icon-default.png");
  }
  &.icon-xbox-360 {
    background-image: url("../img/productTypeIcons/icon-xbox-360.jpg");
  }
  &.icon-xbox-kinect {
    background-image: url("../img/productTypeIcons/icon-xbox-kinect.jpg");
  }
  &.icon-xbox {
    background-image: url("../img/productTypeIcons/icon-xbox.jpg");
  }
  &.icon-xbox-one {
    background-image: url("../img/productTypeIcons/icon-xbox-one.jpg");
  }
  &.icon-psvita {
    background-image: url("../img/productTypeIcons/icon-psvita.jpg");
  }
  &.icon-psp {
    background-image: url("../img/productTypeIcons/icon-psp.jpg");
  }
  &.icon-playstation {
    background-image: url("../img/productTypeIcons/icon-playstation.jpg");
  }
  &.icon-playstation-2 {
    background-image: url("../img/productTypeIcons/icon-playstation-2.jpg");
  }
  &.icon-playstation-3 {
    background-image: url("../img/productTypeIcons/icon-playstation-3.jpg");
  }
  &.icon-playstation-4 {
    background-image: url("../img/productTypeIcons/icon-playstation-4.jpg");
  }
  &.icon-pc {
    background-image: url("../img/productTypeIcons/icon-pc.jpg");
  }
  &.icon-wii {
    background-image: url("../img/productTypeIcons/icon-wii.jpg");
  }
  &.icon-wii-u {
    background-image: url("../img/productTypeIcons/icon-wii-u.jpg");
  }
  &.icon-gameboy-advance {
    background-image: url("../img/productTypeIcons/icon-gameboy-advance.jpg");
  }
  &.icon-nintendo-ds {
    background-image: url("../img/productTypeIcons/icon-nintendo-ds.jpg");
  }
  &.icon-nintendo-3ds {
    background-image: url("../img/productTypeIcons/icon-nintendo-3ds.jpg");
  }
  &.icon-nintendo-switch {
    background-image: url("../img/productTypeIcons/icon-nintendo-switch.jpg");
  }
  &.icon-game {
    background-image: url("../img/productTypeIcons/icon-game.jpg");
  }
  &.icon-toy {
    background-image: url("../img/productTypeIcons/icon-toy@2x.png");
  }
  &.icon-poster {
    background-image: url("../img/productTypeIcons/icon-poster@2x.png");
  }
  &.icon-dvd {
    background-image: url("../img/productTypeIcons/icon-dvd.jpg");
  }
  &.icon-bluray {
    background-image: url("../img/productTypeIcons/icon-bluray.jpg");
  }
  &.icon-cd {
    background-image: url("../img/productTypeIcons/icon-cd.jpg");
  }
  &.icon-vinyl {
    background-image: url("../img/productTypeIcons/icon-vinyl.jpg");
  }
  &.icon-cassette {
    background-image: url("../img/productTypeIcons/icon-cassette.jpg");
  }
}